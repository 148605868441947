.container {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
}

.canvas {
  min-height: 400px;
  display: flex;
  flex-direction: column;
  border-radius: $corner-sm;
  background-color: $color-white;
}

.canvas--grouped {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.header {
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  background-color: $color-gray-025;
  padding-left: $space-xs;
  transition: background-color $transition-speed-fast;
  border-radius: $corner-sm;
}

.header--hovered {
  background-color: $color-gray-200;
}

.header--expanded {
  z-index: $layer-shade;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.header-label-container {
  display: flex;
  align-items: center;
  padding-top: $space-xs;
  padding-bottom: $space-xs;
  flex: 1;

  /**
   * This element functions as a fake button via the `getButtonProps`, so it
   * receives some default styling that we need to disable
   */
  appearance: none;
  cursor: pointer;
}

.header-menu-container {
  position: absolute;
  right: $space-sm;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.participant-icon circle {
  fill: var(--base);
}

.participant-icon path {
  fill: var(--light);
}

.participant-icon-group {
  --group-gutter: $space-sm;
  position: relative;

  /**
   * Width is the width of thte participant icon plus the width of the gutter
   * for each icon after the first
   */
  width: calc(24px + (var(--count) - 1) * var(--group-gutter));
  height: 24px;

  /**
   * Need this to preserve the stacking order on the transforms.
   */
  transform-style: preserve-3d;
}

.participant-icon-group > .participant-icon-group-item {
  position: absolute;
  top: 0;
  left: 0;

  /**
   * For each item, transform is to the right by a fixed amount and place it at
   * a lower `z` than the item before it. This creates the stacked effect with
   * item sitting on top and a little to the left of the next item
   */
  transform: translate3d(
    calc(var(--position) * var(--group-gutter) * 1),
    0,
    calc(var(--position) * 1px * -1)
  );
}

.participant-icon-group-item circle {
  stroke: $color-white;
  stroke-width: 1px;
}
