.container {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: calc($layer-modal + 10);
  bottom: 0;
}

.messageContainer {
  display: flex;
  justify-content: center;
  transition: $transition-speed-fast opacity;
}

.message {
  align-items: center;
  background: $color-gray-800;
  border-radius: $corner-sm;
  box-shadow: 0 2px 32px rgba(0, 0, 0, 0.15);
  color: $color-white;
  display: inline-flex;
  font-size: $font-size-ms;
  justify-content: space-between;
  margin: 0 0 $space-md;
  padding: $space-sm $space-md;
}

.message .iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 -12px;
}

.message .iconWrapper svg {
  margin-right: 4px;
}
