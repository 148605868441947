/* Hard-coding these values because they are needed in multiple components to */
/* get everything to line up */
$BULLET_SIZE: 4px;
$BULLET_OFFSET_TOP: 12px;
$BULLET_OFFSET_LEFT: 12px;
$ROW_OFFSET_BOTTOM: 8px;

.row {
  margin-bottom: $ROW_OFFSET_BOTTOM;
  position: relative;
  width: 100%;
}

.row--last {
  margin-bottom: 0;
}

.line {
  position: absolute;
  width: $BULLET_SIZE;
  background-color: $color-gray-100;
  left: $BULLET_OFFSET_LEFT;

  height: calc(100% + $ROW_OFFSET_BOTTOM);
  top: 0;
}

.line--first {
  /**
   * The first line needs to 100% height minus the top offset, plus the
   * margin between the rows so that we span the height to the next row
   */
  height: calc(100% - $BULLET_OFFSET_TOP + $ROW_OFFSET_BOTTOM);

  /* Start where the bullet starts instead of at the very top */
  top: $BULLET_OFFSET_TOP;

  border-top-left-radius: 100rem;
  border-top-right-radius: 100rem;
}

.line--last {
  /**
   * The last line needs to only be as height as the top offset and the
   * bullet itself so it doesn't span the full height
   */
  height: calc($BULLET_OFFSET_TOP + $BULLET_SIZE);
  top: 0;

  border-bottom-left-radius: 100rem;
  border-bottom-right-radius: 100rem;
}

.selectorCell {
  margin-right: $space-xs;

  /* TODO: change this for each one of the fields */
  width: 24%;
}

.selectWrapper {
  margin-right: $space-xs;
  margin-left: $space-xs;
}

.grid {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: $space-ms;
}

.bulletWrapper {
  padding-left: $BULLET_OFFSET_LEFT;
  padding-right: $BULLET_OFFSET_LEFT;
  padding-top: $BULLET_OFFSET_TOP;
  padding-bottom: $BULLET_OFFSET_TOP;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.bulletIcon {
  width: $BULLET_SIZE;
  height: $BULLET_SIZE;
  background-color: $color-gray-400;
  border-radius: $corner-round;
  z-index: 1;
}

.highlightedText {
  border-radius: $corner-sm;
  background-color: $color-gray-100;
  font-size: $font-size-ms;
  font-weight: $font-weight-semibold;
  color: $color-text-standard;
  display: inline-flex;
  align-items: center;
  padding-right: $space-sm;
  padding-left: $space-sm;
  padding-top: $space-xs;
  padding-bottom: $space-xs;
}

.highlightedText--disabled {
  background-color: $color-transparent;
}

.highlightedText--withDropdown {
  padding-top: 2px;
  padding-bottom: 2px;
}

.controlRuleContainer {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
}

.controlRuleContainer--visible {
  opacity: 1;
  pointer-events: all;
}

.error {
  font-size: $font-size-sm;
  color: $color-text-error;
}

.errorWrapper {
  margin-top: $space-xs;

  /* Ensure the errors line up with the content of each row */
  margin-left: calc($BULLET_OFFSET_LEFT * 2 + $BULLET_SIZE);
}
