/* date-input.module.css */

.container {
  display: inline-block;
  width: 100%;
  max-width: 400px;
}

.input {
  color: $color-gray-700;
  border: 1px solid $color-gray-400;
  font-size: 16px;
  box-shadow: none;
  border-radius: 2px;
  padding: 8px 12px;
  letter-spacing: 0.05em;
  transition: box-shadow 0.2s, border-color 0.2s;
  height: 42px;
  width: 100%;
  white-space: nowrap;
}

.input:hover {
  border-color: $color-gray-500;
}

.input.invalid {
  border: 1px solid $color-red-500;
}

.input:focus {
  outline: none;
  border-color: $color-ui-focus;
}

.input:disabled {
  background-color: $color-input-bg-disabled;
  color: $color-gray-500;
  border: solid 1px $color-input-border-disabled;
  -webkit-text-fill-color: $color-gray-500;
  cursor: not-allowed;
}
