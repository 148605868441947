.wrapper {
  display: inline-block;
  width: 100%;
}

.counter {
  float: right;
  font-size: $font-size-sm;
  color: $color-text-dim;
}
