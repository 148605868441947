/**
 * This is the primary wrapper around a merge field tag. It's got a few style
 * hacks in an attempt to get the cursor to position itself correctly around
 * the node.
 */
.data-node {
  display: inline-block;
  line-height: 1;
  text-decoration: inherit;
  cursor: pointer;
}

/**
 * Some nasty looking selectors here, but we need combine local and global
 * styles in order to style the selected data node
 */
.data-node:global(.ProseMirror-selectednode) {
  box-shadow: none;
}

/*
 * Not the best selector, but this was the only way I could get the data
 * ref node to "inherit" multiple text decorations
 *
 * <div class='ProseMirror'>
 *   <s>
 *     <u>
 *       <span class='data-node'></span>
 *     </u>
 *   </s>
 * </div>
 */
:global(.ProseMirror s > u) > :local(.data-node) {
  text-decoration: line-through underline;
}

.data-node:global(.ProseMirror-selectednode) > :local(.merge-field-tag) {
  box-shadow: 0 0 0px 1px $color-gray-200;
  outline: none;
}

.merge-field-tag {
  display: inline;
  background-color: $color-gray-050;
  border: 1px solid $color-gray-600;
  border-radius: $corner-sm;
  padding: 0px $space-xs;
}
