/* Only apply space between pages and not after */
.page + .page {
  margin-top: $space-md;
}

.scrollContainer {
  padding-right: $space-xs;
}

/**
 * This is needed for Firefox/Safari. For some reason FF will not take bottom
 * padding into account when determining the scroll height, so if we try to apply
 * bottom padding, part of the scrollable content will be out of reach
 */
.scrollContainer:after {
  content: "";
  display: block;
  height: $space-md;
}

/**
 * The fields are styled in collaboration with the `DocumentMapper` component 
 * that applies some basic styles for positioning and also applies a 
 * `data-state` attribute that describes the current state from the 
 * `DocumentMapper` component. The field can be in the one of the following 
 * states:
 *
 *   - `idle` - (from DocumentMapper)
 *   - `selected` (from DocumentMapper)
 *   - `dragging` (from DocumentMapper)
 *   - `resizing` (from DocumentMapper)
 *   - `hinted` - The field's corresponding component is being hovered (from PdfMapper)
 *   - `highlighted` - The field's corresponding component is selected (from PdfMapper)
 */
.field {
  padding: 0;
  display: flex;
  transition-property: background-color, box-shadow;
  transition-duration: $transition-speed-fast;
  background-color: var(--light);
  opacity: 0.9;
  border: none;
  align-items: center;

  /* Covers default styling for Safari/FF */
  color: inherit;
}

.field:focus {
  outline: none;
  box-shadow: 0 0 0 1px var(--base);
}

.field[data-state="selected"] {
  background: var(--gradient);
}

.field[data-state="selected"],
.field[data-extended-state="hovered"],
.field:hover {
  box-shadow: 0 0 0 1px var(--base);
}

.field[data-extended-state="highlighted"]:after,
.field[data-extended-state="hinted"]:after,
.field[data-state="idle"]:after {
  content: "";
  height: 2px;
  background-color: var(--base);
  width: 100%;
  position: absolute;
  bottom: -2px;
  left: 0;
}

.field[data-state="dragging"] {
  border: none;
  box-shadow: $shadow-3;
  cursor: grabbing;
}

/**
 * Avoid mouse events while dragging or resizing for performance reasons
 */
.field[data-state="dragging"],
.field[data-state="resizing"] {
  pointer-events: none;
}

.field[data-extended-state="hinted"],
.field[data-extended-state="highlighted"] {
  box-shadow: 0 0 0 2px $color-white;
  background-color: var(--base);
  color: $color-white;
}

.label {
  display: flex;
  align-items: center;
  line-height: 1;

  /**
   * A `min-width` is needed to ensure truncated text kicks on the child.
   * Mimics the styles in `TruncatedTextParent`.
   */
  min-width: 0;
}

.label--Signature {
  padding-left: $space-xs;
  /* Font size is not set by the mapping for signature mappings */
  font-size: $font-size-sm;
}

.label--Multiline {
  padding-left: $space-xs;
  padding-right: $space-xs;
  height: 100%;
  width: 100%;
}

.resize-handle {
  position: absolute;
  width: 6px;
  height: 6px;
  background-color: $color-white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  border: 1px solid var(--base);
  border-radius: 100%;
  opacity: 0;
  transition: opacity $transition-speed-fast;
}

.resize-handle:focus {
  opacity: 1;
}

/**
 * This is exposing the internals of the document mapper component
 */
.field[data-state="selected"] ~ .resize-handle,
.field:hover ~ .resize-handle {
  opacity: 1;
}
