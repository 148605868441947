.badgeContainer {
  background-color: $color-purple-100;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding-left: $space-xs;
  padding-right: $space-xs;
  border-radius: $corner-sm;
  /* TODO: this margin left is part of the premium badge since we are only
   * using it in one part, but once we use it somewhere else, we need to remove
   * it from the base container */
  margin-left: $space-sm;
}

.badgeLabel {
  margin-left: $space-xs;
  text-transform: uppercase;
  font-weight: $font-weight-semibold;
  font-size: $font-size-sm;
  line-height: $font-size-md;
  color: $color-purple-600;
}

.settingsContainer {
  display: flex;
  line-height: $font-size-md;
  padding: $space-ms;
  background: $color-blue-050;
  border-top: 1px solid $color-blue-100;
  border-bottom: 1px solid $color-blue-100;
  font-size: $font-size-sm;
}

.iconWrapper {
  margin-right: $space-xs;
}

.link {
  color: $color-blue-600;
  text-decoration: underline;
  white-space: nowrap;
  font-weight: $font-weight-normal;
}

.link:hover,
.link:active {
  font-weight: $font-weight-normal;
}

.previewContainer {
  position: absolute;
  top: $space-sm;
  left: 0;
  z-index: 401; /* place above the component menu, else the tooltip doesn't get triggered near the top of the sparkle */
}

.tooltipArea {
  width: $space-lg;
  height: $space-lg;
  display: flex;
  align-items: center;
  justify-content: center;
}
