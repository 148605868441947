.label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: $space-ms;
  color: $color-text-dim;
  font-size: $font-size-sm;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: $font-weight-semibold;
  overflow: hidden;
  text-overflow: ellipsis;
}

.divider {
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: $color-divider-dim;
}

.labelText {
  padding-top: $space-sm;
  padding-bottom: $space-sm;
}

.emptySearch {
  color: $color-text-light;
  font-style: italic;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: $font-size-ms;
  padding: $font-size-xs;
}
