.content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* To position the scroll bar correctly fro step 1 we strip the padding from the modal body, but we still want it for
   certain elements so then we reapply it as a margin on the children. */
.step1Adjustment {
  margin-left: $space-lg;
  margin-right: $space-lg;
}
