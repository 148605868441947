.upgradeBanner {
  background-color: $color-yellow-050;
  padding: $space-ms;
  font-size: $font-size-ms;
  display: flex;
}

.warningIcon {
  padding-right: $space-sm;
}

.upgradeText {
  margin: auto 0;
}
