.standard {
  display: inline-flex;
}

.standard > button {
  border-radius: 0px;
}

.compacted {
  margin-right: 0px;
}

.expanded > button {
  margin-right: 2px;
}

.standard > button:first-of-type {
  border-top-left-radius: $corner-sm;
  border-bottom-left-radius: $corner-sm;
}

.standard > button:last-of-type {
  border-top-right-radius: $corner-sm;
  border-bottom-right-radius: $corner-sm;
  margin-right: 0px;
}
