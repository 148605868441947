.dropZone {
  width: 100%;
  padding: $space-lg 0;
}

.addIcon {
  margin: 0 $space-md;
  vertical-align: middle;
}

.addText {
  font-weight: $font-weight-semibold;
  color: $color-gray-900;
}
