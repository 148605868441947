/* file-attachment-component.module.css */

.container {
  width: 100%;
  max-width: 400px;
}

.errorMessage {
  margin-top: $space-ms;
}

.instructions {
  margin-top: $space-sm;
  font-size: $font-size-ms;
  color: $color-gray-800;
}

.readOnlyContent,
.dropZone {
  border-radius: $corner-sm;
  font-size: $font-size-md;
  margin-top: $space-md;
  padding: $space-ms;
  width: 100%;
  color: $color-gray-600;
}

.readOnlyContent {
  background-color: $color-gray-100;
  border: solid 1px $color-gray-200;
}

.dropZone {
  border: 2px dashed $color-gray-200;
}

.uploading .dropZone {
  border-color: transparent;
  padding: 0;
  margin-bottom: 9px; /* So that the no file/file name state and the loading state don't cause a flicker */
}

.valid .dropZone {
  color: $color-gray-800;
}

.rejected .dropZone {
  color: $color-gray-500;
  border-color: $color-red-500;
}

.dropZoneActive .dropZone {
  background: #d7f5fa;
  border-color: #0192c2;
}

.hidden {
  display: none;
}

.selectFile {
  align-self: flex-start;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.loadingContainer {
  align-self: flex-start;
  width: 100%;
  padding: $space-xs;
  color: $color-gray-600;
  font-size: $font-size-sm;
}

.loadingBar {
  width: 100%;
  border-radius: $corner-md;
  height: $space-sm;
  background: $color-gray-300;
  margin: $space-xs 0;
}

.loadingBarInner {
  height: $space-sm;
  border-radius: $space-xs;
  background: #4a90e2;
}

.delete.delete {
  /* Hack to give this more specificity than the default icon style */
  align-self: flex-end;
  margin: -$space-xs 0 0 10px;
  padding: 0;
}

.statusIcon.statusIcon {
  /* Hack to give this more specificity than the default icon style */
  padding: 0 $space-ms 0 0;
  height: 20px;
  vertical-align: middle;
}

.statusIcon:focus {
  box-shadow: none;
}

.fileHint {
  color: $color-gray-600;
  font-size: $font-size-ms;
  padding: 13px 0 0;
}

.dropZoneContent {
  display: flex;
}
