.row {
  display: flex;
  padding: $space-md 0;
}

.subRow {
  display: flex;
  padding: 0 0 $space-md;
}

.numberCol {
  width: 1em;
}

.error {
  color: $color-red-800;
  font-size: $font-size-ms;
}

.dangerIcon {
  padding-right: $space-sm;
}

.errorText {
  margin: auto 0;
}
