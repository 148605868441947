.label {
  font-size: $font-size-ms;
  margin-bottom: $space-xs;
  margin-right: $space-xs;
}

.container {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
}

.selectorContainer {
  width: 150px;
}
