.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: $space-sm;
  max-width: 464px;
}

.title {
  text-transform: uppercase;
  color: $color-text-dim;
  font-size: $font-size-sm;
  letter-spacing: 1px;
  font-weight: $font-weight-semibold;
  cursor: default;
  padding: $space-sm $space-ms;
  line-height: 16px;
}

.cell {
  width: 112px;
  padding: $space-ms;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: $font-size-sm;
  cursor: pointer;
  background-color: $color-transparent;
  border-style: none;
  border-radius: $corner-sm;
}

.cell:hover {
  background-color: $color-button-bg-subtle-hover;
}

.cell:active {
  background-color: $color-button-bg-subtle-pressed;
}

.label {
  margin-top: $space-sm;
}

.iconWrapper {
  position: relative;
}

.sparkle {
  position: absolute;
  right: -9px;
  top: -9px;
}
