.formula {
  display: inline-grid;
  flex: auto;
  justify-content: flex-end;
  justify-self: flex-end;
  grid-template-columns: auto auto auto;
  align-items: center;
  column-gap: 0.25rem;
  font-size: $font-size-ms;
  min-width: 0;
}

.calculationArgument {
  background-color: $color-gray-025;
  color: $color-text-darker;
  padding: $space-xs;
  border-width: 1px;
  border-color: $color-gray-400;
  border-style: solid;
  border-radius: $corner-sm;
  letter-spacing: 0.05em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
}
