.tableInput {
  padding: $space-xs;
  border-style: solid;
  border-color: transparent;
  border-width: 1px;
  width: 100%;
}

.tableInput:focus {
  border-color: $color-gray-400;
}

.invalid {
  border-color: $color-red-500;
}

.error {
  background: $color-red-025;
}

.cellErrorIcon {
  margin-right: $space-sm;
  vertical-align: middle;
}

.cellErrorText {
  font-size: $font-size-sm;
}

.container {
  padding: 0 $space-lg $space-lg;
  height: 100%;
  overflow: auto;
}

.blocker {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.85);
  z-index: calc($layer-modal + 1);
  left: 0;
}

.sendingMessage {
  position: absolute;
  left: 50%;
  z-index: calc($layer-modal + 1);
}

.headRow {
  border-bottom: 0;
}

.headRow td {
  border-bottom: 1px solid $color-divider-dim;
}

.td {
  vertical-align: top;
  max-width: 240px;
  padding-top: $space-xs;
  padding-bottom: $space-xs;
}

.th {
  position: sticky;
  top: 0;
  background: $color-white;
}

.invisible {
  height: 0;
  visibility: hidden;
  margin-right: 1rem;
}
