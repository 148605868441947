.header {
  padding: $space-ms;
  font-size: $font-size-ms;
  font-weight: $font-weight-semibold;
  color: $color-text-standard;
  word-break: break-word;
  /* flexbox fallback for grid */
  flex: 1;
}

.first {
  padding-left: 1px;
}

.right {
  justify-content: flex-end;
  padding-right: 0;
}

.sortable {
  cursor: pointer;
  display: flex;
}

.sortable:hover {
  text-decoration: underline;
}

.arrowWrapper {
  display: inline-block;
  vertical-align: middle;
  margin: auto 0 auto 6px;
}

.right .arrowWrapper {
  margin: auto 6px auto 0;
}

.inactiveSort {
  visibility: hidden;
}

.sortable:hover .inactiveSort {
  visibility: visible;
}

.arrowIcon {
  stroke: $color-icon-default;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: $transition-speed-fast;
}

.desc .arrowIcon {
  transform: rotate(180deg);
}

.sortable:hover .arrowIcon {
  stroke: $color-icon-hover;
}

.sortable:hover .inactiveSort .arrowIcon {
  stroke: $color-icon-dim;
}
