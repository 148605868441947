/* This contains the scanning CSS.
Values in the linear gradient are so specific, that they are kept hardcoded
instead of creating references to them */
.scanning::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  height: 100%;
  width: 1px;
  z-index: 2;
  background-color: $color-white;
  box-shadow: 0 0 3px $color-white;
  opacity: 0.3;
  animation: scanning var(--scan-duration) var(--easing), flicker 0.01s;
}

.scanning::after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  mix-blend-mode: screen;
  top: 0;
  left: 0;
  z-index: 1;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.95),
    rgba(153, 153, 153, 0.35) 5%,
    rgba(153, 153, 153, 0.35) 35%,
    rgba(0, 240, 255, 0.6) 49%,
    #00f0ff 50%,
    rgba(255, 255, 255, 0.95) 50%,
    rgba(255, 255, 255, 0.95)
  );
  background-size: 200% 100%;
  background-repeat: no-repeat;
  background-position: 100% center;
  animation: trailing-scan var(--scan-duration) var(--easing);
}

@keyframes trailing-scan {
  100% {
    background-position: 0 center;
  }
}
@keyframes flicker {
  50% {
    opacity: 0;
  }
}
@keyframes scanning {
  100% {
    transform: translateX(612px);
  }
}
