.dropZone {
  border: 2px dashed $color-gray-200;
  background: $color-gray-025;
  cursor: pointer;
}

.dropZone:focus,
.dropZone:hover {
  border-color: unset;
}

.dropZoneActive {
  background: $color-blue-050;
  border-color: $color-blue-600;
}

.hidden {
  display: none;
}
