.step {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.persistentError {
  padding-right: $space-ms;
  background: $color-yellow-050;
  display: flex;
  font-size: $font-size-ms;
  margin-left: $space-lg;
  margin-right: $space-lg;
}

.persistentErrorIcon {
  padding: $space-ms;
}

.persistentErrorText {
  margin: auto 0;
}
